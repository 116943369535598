<template>
  <div class="font">
    <div class="font__title">
      {{ $t(`components.font.${title}`) }}
    </div>

    <div class="font__preview" :style="styles">
      {{ demoText }}
    </div>

    <div class="font__form-fields">
      <div class="font__row">
        <DropDown
          class="font__font-dropdown"
          v-model="chosenFont"
          :list="fonts"
          item-value="name"
          :translation="false"
        >
          <template slot="selected">
            <div class="font__chosen-font-name" v-if="chosenFont">
              {{ chosenFont.name }}
            </div>

            <div class="font__chosen-font-name" v-else>
              {{ $t("components.font.noFont") }}
            </div>
          </template>
        </DropDown>
      </div>
      <div class="font__row">
        <div class="font__form-field">
          <label class="font__label" for="font-size">
            {{ $t("components.font.fontSize") }}
          </label>
          <input
            class="font__input"
            name="font-size"
            type="number"
            v-model="fontSize"
          />
          <span class="font__px">PX</span>
        </div>
        <div class="font__form-field">
          <label class="font__label" for="line-height">
            {{ $t("components.font.lineHeight") }}
          </label>
          <input
            class="font__input"
            name="line-height"
            type="number"
            v-model="lineHeight"
          />
          <span class="font__px">PX</span>
        </div>
      </div>

      <div class="font__row">
        <div class="font__form-field">
          <label class="font__label" for="text-transform">
            {{ $t("components.font.textTransform") }}
          </label>
          <DropDown
            class="font__transform-dropdown"
            v-model="textTransform"
            :list="transformOptions"
            :translation="false"
          >
            <template slot="selected">
              <div class="font__chosen-transform">
                {{ textTransform }}
              </div>
            </template>
          </DropDown>
        </div>
        <div class="font__form-field">
          <label class="font__label" for="letter-spacing">
            {{ $t("components.font.letterSpacing") }}
          </label>
          <input
            class="font__input"
            name="letter-spacing"
            type="text"
            v-model="letterSpacing"
          />
        </div>
      </div>
    </div>

    <Button
      v-if="hasChanged"
      class="font__save-btn"
      theme="secondary"
      @click="onSave"
    >
      {{ $t("components.font.saveBtn") }}
    </Button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Button";
import DropDown from "@/components/DropDown";

export default {
  components: {
    Button,
    DropDown
  },
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    demoText: "the five boxing wizards jump quickly",
    fontSize: "",
    lineHeight: "",
    textTransform: "none",
    letterSpacing: "",
    transformOptions: ["none", "capitalize", "uppercase", "lowercase"],
    chosenFont: {}
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      generalAssets: "getGeneralAssets"
    }),
    styleName() {
      return "style" + this.title.charAt(0).toUpperCase() + this.title.slice(1);
    },
    styles() {
      return {
        "font-family": this.title + "_font",
        "font-size": this.fontSize + "px",
        "line-height": this.lineHeight + "px",
        "text-transform": this.textTransform,
        "letter-spacing": this.letterSpacing + "px"
      };
    },
    fonts() {
      if (this.generalAssets) {
        let generalFonts = Object.entries(this.generalAssets.brand.fonts).map(
          ([id, value]) => ({
            id,
            ...value
          })
        );

        let fonts = [];

        if (this.team.brand && this.team.brand.fonts) {
          fonts = Object.entries(this.team.brand.fonts).map(([id, value]) => ({
            id,
            ...value
          }));
        }

        if (fonts.length > 0) {
          fonts.forEach(font => {
            generalFonts.push(font);
          });
        }

        return generalFonts;
      }

      return [];
    },
    isAlreadySet() {
      if (
        this.team.brand &&
        this.team.brand.textStyles &&
        this.team.brand.textStyles[this.styleName]
      ) {
        return !!this.team.brand.textStyles[this.styleName].fontId;
      }

      return false;
    },
    hasChanged() {
      if (this.isAlreadySet) {
        if (
          this.fontSize ===
            this.team.brand.textStyles[this.styleName].fontSize &&
          this.lineHeight ===
            this.team.brand.textStyles[this.styleName].lineHeight &&
          this.textTransform ===
            this.team.brand.textStyles[this.styleName].textTransform &&
          this.letterSpacing ===
            this.team.brand.textStyles[this.styleName].letterSpacing &&
          this.chosenFont.id ===
            this.team.brand.textStyles[this.styleName].fontId
        ) {
          return false;
        }

        return true;
      } else {
        if (
          this.fontSize === "" &&
          this.lineHeight === "" &&
          this.textTransform === "none" &&
          this.letterSpacing === "" &&
          this.chosenFont === null
        ) {
          return false;
        } else {
          return !(this.chosenFont === null);
        }
      }
    }
  },
  watch: {
    team() {
      if (this.isAlreadySet) {
        this.fontSize = this.team.brand.textStyles[this.styleName].fontSize;
        this.lineHeight = this.team.brand.textStyles[this.styleName].lineHeight;
        this.textTransform = this.team.brand.textStyles[
          this.styleName
        ].textTransform;
        this.letterSpacing = this.team.brand.textStyles[
          this.styleName
        ].letterSpacing;

        this.chosenFont = this.getFont(
          this.team.brand.textStyles[this.styleName].fontId
        );
        this.chosenFont.id = this.team.brand.textStyles[this.styleName].fontId;

        this.setPreviewFont();
      } else {
        this.fontSize = "";
        this.lineHeight = "";
        this.textTransform = "none";
        this.letterSpacing = "";
      }
    },
    chosenFont() {
      this.setPreviewFont();
    }
  },
  mounted() {
    if (this.isAlreadySet) {
      this.fontSize = this.team.brand.textStyles[this.styleName].fontSize;
      this.lineHeight = this.team.brand.textStyles[this.styleName].lineHeight;
      this.textTransform = this.team.brand.textStyles[
        this.styleName
      ].textTransform;
      this.letterSpacing = this.team.brand.textStyles[
        this.styleName
      ].letterSpacing;

      this.chosenFont = this.getFont(
        this.team.brand.textStyles[this.styleName].fontId
      );
      this.chosenFont.id = this.team.brand.textStyles[this.styleName].fontId;
      this.setPreviewFont();
    }
  },
  methods: {
    ...mapActions("teams", ["updateTeam", "uploadFont", "updateFont"]),
    ...mapActions("notifications", ["addNotification"]),
    setPreviewFont() {
      let newStyle = document.createElement("style");

      newStyle.appendChild(
        document.createTextNode(
          "\
              @font-face {\
                  font-family: '" +
            this.title +
            "_font';\
                  src: url('" +
            this.chosenFont.url +
            "')\
                  format('woff');\
              }\
              "
        )
      );

      document.head.appendChild(newStyle);
    },
    getFont(id) {
      if (this.team?.brand?.fonts) {
        let brandFonts = Object.keys(this.team.brand.fonts);

        if (brandFonts.some(font => font === id)) {
          return this.team.brand.fonts[id];
        } else {
          return this.generalAssets.brand.fonts[id];
        }
      } else {
        return this.generalAssets.brand.fonts[id];
      }
    },
    getName() {
      if (this.title === "title") {
        return "Title";
      } else if (this.title === "headline") {
        return "Headline";
      } else if (this.title === "subHeadline") {
        return "Sub-Headline";
      } else if (this.title === "normalText") {
        return "Normal Text";
      } else if (this.title === "smallText") {
        return "Small Text";
      } else if (this.title === "caption") {
        return "Caption";
      }
    },
    onSave() {
      let brand = {};

      if (this.team.brand) {
        brand = this.team.brand;
      }

      let typography = {};
      let name = this.getName();

      if (brand.textStyles) {
        typography = brand.textStyles;

        typography[this.styleName] = {
          ...typography[this.styleName],
          fontName: this.chosenFont.name,
          name: name,
          fontFamily: this.title + "_font",
          fontSize: this.fontSize ? this.fontSize : 16,
          lineHeight: this.lineHeight ? this.lineHeight : "",
          textTransform: this.textTransform ? this.textTransform : "",
          letterSpacing: this.letterSpacing ? this.letterSpacing : "",
          fontId: this.chosenFont.id
        };
      } else {
        typography[this.styleName] = {
          name: name,
          fontName: this.chosenFont.name,
          fontFamily: this.title + "_font",
          fontSize: this.fontSize ? this.fontSize : 16,
          lineHeight: this.lineHeight ? this.lineHeight : "",
          textTransform: this.textTransform ? this.textTransform : "none",
          letterSpacing: this.letterSpacing ? this.letterSpacing : "",
          fontId: this.chosenFont.id
        };
      }

      brand.textStyles = typography;

      this.team.brand = brand;

      this.updateTeam(this.team);
    }
  }
};
</script>

<style lang="scss">
.font {
  &__title {
    @apply mb-2 font-semibold text-base text-ls-gray-800;
  }

  &__preview {
    @apply mb-6 border border-ls-gray-400 bg-white rounded-sm px-2 py-2 border-dashed overflow-hidden max-h-48;
  }

  &__form-fields {
  }

  .dropdown__selected-wrapper {
    @apply bg-ls-gray-100 p-2 rounded-lg text-sm border-none;
  }

  &__row {
    @apply flex mb-4;
  }

  &__font-dropdown {
    @apply w-full;

    .dropdown__wrapper {
      @apply w-full;
    }
  }

  &__form-field {
    width: calc(50% - 8px);
    @apply flex flex-col relative;

    &:nth-child(odd) {
      @apply mr-4;
    }
  }

  &__label {
    @apply text-xs mb-1;
  }

  &__input {
    @apply bg-ls-gray-100 p-2 rounded-lg text-sm;
  }

  &__px {
    @apply font-semibold text-sm absolute;
    right: 28px;
    top: 30px;
  }

  &__upload-btn-input {
    display: none;
  }

  &__upload-btn {
    @apply border border-ls-gray-400 bg-white rounded-lg px-3 py-2 cursor-pointer text-xs font-semibold;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    display: inline-block;
  }

  &__save-btn {
    @apply mt-4 w-full;
  }
}
</style>
