<template>
  <div class="color-modal">
    <div class="color-modal__content">
      <div class="color-modal__heading">
        {{ $t("components.colorModal.heading") }}
      </div>
      <Button
        class="color-modal__close-btn"
        @click="onClose"
        icon="X"
        theme="secondary"
      >
        {{ $t("components.colorModal.close") }}
      </Button>

      <div class="color-modal__content-inner">
        <div class="color-modal__picker" ref="picker"></div>
        <div class="color-modal__input-wrapper">
          <label class="color-modal__label">
            {{ $t("components.colorModal.hex") }}
          </label>
          <input class="color-modal__input" v-model="inputColor" />
        </div>

        <Button class="color-modal__set-btn" @click="setColor">
          <span v-if="chosenColor">
            {{ $t("components.colorModal.saveColor") }}
          </span>
          <span v-else>{{ $t("components.colorModal.uploadColor") }}</span>
        </Button>
      </div>
    </div>
    <div class="color-modal__backdrop" @click="onClose"></div>
  </div>
</template>

<script>
import iro from "@jaames/iro";
import Button from "./Button";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Button
  },
  props: {
    colorType: {
      type: String,
      default: "secondary"
    },
    chosenColor: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    colorPicker: null,
    inputColor: ""
  }),
  mounted() {
    this.colorPicker = new iro.ColorPicker(this.$refs.picker, {
      layout: [
        {
          component: iro.ui.Box,
          options: {}
        },
        {
          component: iro.ui.Slider,
          options: { sliderType: "hue", sliderSize: 16 }
        }
        // {
        //   component: iro.ui.Slider,
        //   options: {
        //     // can also be 'saturation', 'value', 'red', 'green', 'blue', 'alpha' or 'kelvin'
        //     sliderType: "alpha",
        //     sliderSize: 16
        //   }
        // }
      ],
      width: 160,
      color: this.chosenColor === "" ? "#f00" : this.chosenColor
    });

    this.inputColor = this.chosenColor === "" ? "" : this.chosenColor;

    this.colorPicker.on("color:change", color => {
      this.inputColor = color.hexString;
    });
  },
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam"
    }),
    color() {
      if (this.colorPicker) {
        return this.colorPicker.color.hexString;
      }

      return "fff";
    }
  },
  watch: {
    inputColor() {
      if (this.inputColor.length > 6) {
        this.colorPicker.color.hexString = this.inputColor;
      }
    }
  },
  methods: {
    ...mapActions("teams", ["updateTeam"]),
    ...mapActions("notifications", ["addNotification"]),
    onClose() {
      this.$emit("close");
    },
    setColor() {
      let brand = {};
      let id =
        this.colorType === "secondary" ? new Date().getTime() : this.colorType;

      if (this.team.brand) {
        brand = this.team.brand;
      }

      if (!brand.colors) {
        brand.colors = {};
      }

      brand.colors[id] = { color: this.color };

      this.team.brand = brand;

      this.updateTeam(this.team)
        .then(() => {
          this.addNotification({
            title: "colorAdded",
            type: "success",
            autoRemove: true
          });
        })
        .catch(() => {
          this.addNotification({
            title: "error",
            type: "error",
            autoRemove: true
          });
        });
      this.onClose();
    }
  }
};
</script>

<style lang="scss">
.color-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 100;

  &__content {
    width: calc(100% - 2rem);
    border-radius: 4px;
    background-color: #fff;
    padding: 1.5rem;
    z-index: 5;
    position: absolute;
    height: fit-content;

    &-inner {
      @apply mt-4;
    }
  }

  &__picker {
    margin: 0 auto;
    width: 160px;
  }

  &__input-wrapper {
    @apply flex flex-col mt-4;
  }

  &__input {
    @apply bg-ls-gray-100 text-sm rounded-lg px-2 py-3;
  }

  &__label {
    @apply font-semibold text-xs;
  }

  &__heading {
    font-size: 28px;
    font-weight: 600;
    margin-top: 2.5rem;
  }

  &__close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__set-btn {
    @apply w-full mt-4;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    background-color: #000;
    opacity: 0.2;
    z-index: 2;
  }
}

@media screen and (min-width: 900px) {
  .color-modal {
    &__content {
      min-width: 580px;
      width: auto;
      border-radius: 4px;
      background-color: #fff;
      padding: 1.5rem;
      z-index: 5;
      position: absolute;
    }

    &__heading {
      font-size: 28px;
      font-weight: 600;
      margin-top: 0;
    }
  }
}
</style>
