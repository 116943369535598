<template>
  <div class="fonts">
    <Card>
      <div class="fonts__list">
        <div class="fonts__font" v-for="(font, index) in fonts" :key="index">
          <div>
            <div class="fonts__font-name" :style="getStyle(index)">
              {{ font.name }}
            </div>
            <div class="fonts__font-created">
              {{ $t("pages.brandmanager.fonts.createdAt") }}
              {{ getDate(font.createdAt) }}
            </div>
          </div>
          <inline-svg
            class="fonts__delete-icon"
            :src="require(`../../assets/icons/X.svg`)"
            aria-label="Delete"
            @click="onDeleteFont(font.id)"
          ></inline-svg>
        </div>
      </div>
      <FontUpload />
    </Card>
  </div>
</template>

<script>
import Card from "@/components/Card";
import FontUpload from "@/components/FontUpload";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Card,
    FontUpload
  },
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam"
    }),
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    fonts() {
      if (this.team.brand && this.team.brand.fonts) {
        let fonts = Object.entries(this.team.brand.fonts).map(
          ([id, value]) => ({
            id,
            ...value
          })
        );

        return fonts;
      }

      return [];
    }
  },
  watch: {
    fonts() {
      this.createStyles();
    }
  },
  mounted() {
    const storedTeam = sessionStorage.getItem("currentTeam");

    if (!this.user?.currentTeam && !storedTeam) {
      this.fetchUserProfile().then(() => {
        this.fetchCurrentTeam(this.user.currentTeam).then(() => {
          this.createStyles();
        });
      });
    } else if (!this.team?.brand && storedTeam) {
      this.fetchCurrentTeam(storedTeam).then(() => {
        this.createStyles();
      });
    } else if (!this.team?.brand && !storedTeam) {
      this.fetchCurrentTeam(this.user.currentTeam).then(() => {
        this.createStyles();
      });
    } else {
      this.createStyles();
    }
  },
  methods: {
    ...mapActions("teams", ["fetchCurrentTeam", "deleteFont"]),
    ...mapActions("user", ["fetchUserProfile"]),
    ...mapActions("notifications", ["addNotification"]),
    getDate(date) {
      return new Date(date).toLocaleString();
    },
    getStyle(index) {
      return {
        "font-family": `font_${index}`
      };
    },
    createStyles() {
      this.fonts.forEach((font, index) => {
        let newStyle = document.createElement("style");

        newStyle.appendChild(
          document.createTextNode(
            "\
              @font-face {\
                  font-family: 'font_" +
              index +
              "';\
                  src: url('" +
              font.url +
              "')}\
              "
          )
        );

        document.head.appendChild(newStyle);
      });
    },
    onDeleteFont(id) {
      let textStyles = Object.entries(this.team.brand.textStyles).map(
        ([id, value]) => ({
          id,
          ...value
        })
      );

      let usingFont = textStyles.filter(textStyle => textStyle.fontId === id);

      if (usingFont.length > 0) {
        this.addNotification({
          title: "fontInUse",
          type: "warning",
          autoRemove: true
        });
      } else {
        this.deleteFont(id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fonts {
  &__list {
    @apply mb-6;
  }

  &__font {
    @apply py-2 border-b flex justify-between items-center;
    border-color: #ebebeb;
  }

  &__font-name {
    @apply text-xl;
  }

  &__font-created {
    @apply text-xs text-ls-gray-800;
  }

  &__delete-icon {
    cursor: pointer;
  }
}
</style>
