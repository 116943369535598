var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"colors"},[_c('Card',[_c('div',{staticClass:"colors__primary"},[_c('div',{staticClass:"colors__color-heading"},[_vm._v(" "+_vm._s(_vm.$t("pages.brandmanager.colors.primary"))+" ")]),_c('div',{staticClass:"colors__color",style:({
          'background-color': _vm.primaryColor.color
        }),on:{"click":function($event){return _vm.openColorModal(_vm.primaryColor)}}})]),_c('div',{staticClass:"colors__divider"}),_c('div',{staticClass:"colors__text-colors"},[_c('div',{staticClass:"colors__color-heading"},[_vm._v(" "+_vm._s(_vm.$t("pages.brandmanager.colors.textColors"))+" ")]),_c('div',{staticClass:"colors__color-wrapper"},[_c('div',{staticClass:"colors__color-text-light"},[_c('label',{staticClass:"colors__color-label",attrs:{"for":"colors__color-text-light"}},[_vm._v(" "+_vm._s(_vm.$t("pages.brandmanager.colors.textLight"))+" ")]),_c('div',{staticClass:"colors__color",style:({
              'background-color': _vm.textLight?.color
            }),attrs:{"id":"colors__color-text-light"},on:{"click":function($event){return _vm.openColorModal(_vm.textLight)}}})]),_c('div',{staticClass:"colors__color-text-dark"},[_c('label',{staticClass:"colors__color-label",attrs:{"for":"colors__color-text-dark"}},[_vm._v(" "+_vm._s(_vm.$t("pages.brandmanager.colors.textDark"))+" ")]),_c('div',{staticClass:"colors__color",style:({
              'background-color': _vm.textDark?.color
            }),attrs:{"id":"colors__color-text-dark"},on:{"click":function($event){return _vm.openColorModal(_vm.textDark)}}})])])]),_c('div',{staticClass:"colors__divider"}),(_vm.brandColors)?_c('div',{staticClass:"colors__secondary-colors",on:{"mouseover":function($event){_vm.showColorDelete = true},"mouseleave":function($event){_vm.showColorDelete = false}}},[_c('div',{staticClass:"colors__color-heading"},[_vm._v(" "+_vm._s(_vm.$t("pages.brandmanager.colors.secondary"))+" ")]),_c('div',{staticClass:"colors__color-wrapper"},_vm._l((_vm.secondaryColors),function(color,index){return _c('div',{key:index,staticClass:"colors__color colors__secondary-color",style:({
            'background-color': color.color
          }),on:{"mouseover":function($event){return _vm.showColor(index)},"click":function($event){return _vm.openColorModal(color)}}},[(_vm.showColorIndex === index && _vm.showColorDelete)?_c('div',{staticClass:"colors__delete"},[_c('inline-svg',{staticClass:"colors__delete-icon",attrs:{"src":require('../../assets/icons/X.svg'),"aria-label":"Close"},on:{"click":function($event){return _vm.deleteColor(color.id)}}})],1):_vm._e()])}),0)]):_vm._e(),_c('Button',{staticClass:"colors__add-color-btn",attrs:{"theme":"secondary"},on:{"click":function($event){return _vm.openColorModal()}}},[_vm._v(" "+_vm._s(_vm.$t("pages.brandmanager.colors.addButton"))+" ")]),(_vm.showColorModal)?_c('ColorModal',{attrs:{"color-type":_vm.colorType,"chosen-color":_vm.chosenColor},on:{"close":_vm.closeColorModal}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }