<template>
  <div class="typography">
    <Card>
      <div v-if="generalAssets">
        <Font title="title" />
        <div class="typography__divider"></div>

        <Font title="headline" />
        <div class="typography__divider"></div>

        <Font title="subHeadline" />
        <div class="typography__divider"></div>

        <Font title="normalText" />
        <div class="typography__divider"></div>

        <Font title="smallText" />
        <div class="typography__divider"></div>

        <Font title="caption" />
      </div>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Font from "@/components/Font";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Card,
    Font
  },
  data: () => ({
    showTypographyModal: false
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      generalAssets: "getGeneralAssets"
    })
  },
  mounted() {
    this.fetchGeneralAssets();
  },
  methods: {
    ...mapActions("teams", ["fetchGeneralAssets"])
  }
};
</script>

<style lang="scss" scoped>
.typography {
  &__divider {
    height: 1px;
    @apply w-full bg-ls-gray-200 my-6;
  }
}
</style>
