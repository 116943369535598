<template>
  <div class="logo">
    <Card>
      <div
        class="logo__logo"
        v-if="team.brand && team.brand.logo"
        :style="{
          'background-image': 'url(' + team.brand.logo + ')'
        }"
      ></div>
      <Button theme="secondary" @click="showPhotoUploadModal = true">
        {{ $t("pages.brandmanager.logo.uploadButton") }}
      </Button>
      <PhotoUploadModal
        v-if="showPhotoUploadModal"
        @close="showPhotoUploadModal = false"
        avatar-type="logo"
        :upload-type="uploadType"
        :use-cropper="false"
      ></PhotoUploadModal>
    </Card>

    <Card class="mt-6">
      <div
        class="logo__logo logo__logo--dark"
        v-if="team.brand && team.brand.logoDark"
        :style="{
          'background-image': 'url(' + team.brand.logoDark + ')'
        }"
      ></div>
      <Button theme="secondary" @click="showPhotoDarkUploadModal = true">
        {{ $t("pages.brandmanager.logo.uploadDarkButton") }}
      </Button>
      <PhotoUploadModal
        v-if="showPhotoDarkUploadModal"
        @close="showPhotoDarkUploadModal = false"
        avatar-type="logoDark"
        :upload-type="uploadDarkType"
        :use-cropper="false"
      ></PhotoUploadModal>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Button from "@/components/Button";
import PhotoUploadModal from "@/components/PhotoUploadModal";
import { mapGetters } from "vuex";

export default {
  components: {
    Card,
    Button,
    PhotoUploadModal
  },
  data: () => ({
    showPhotoUploadModal: false,
    showPhotoDarkUploadModal: false
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam"
    }),
    uploadType() {
      if (this.team.brand && this.team.brand.logo) {
        return "update";
      }

      return "upload";
    },
    uploadDarkType() {
      if (this.team.brand && this.team.brand.logoDark) {
        return "update";
      }

      return "upload";
    }
  }
};
</script>

<style lang="scss" scoped>
.logo {
  &__logo {
    width: 200px;
    height: 200px;
    @apply mb-6 bg-contain bg-no-repeat bg-center;

    &--dark {
      background-color: #000;
    }
  }
}
</style>
