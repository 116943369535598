<template>
  <div class="colors">
    <Card>
      <div class="colors__primary">
        <div class="colors__color-heading">
          {{ $t("pages.brandmanager.colors.primary") }}
        </div>
        <div
          class="colors__color"
          :style="{
            'background-color': primaryColor.color
          }"
          @click="openColorModal(primaryColor)"
        ></div>
      </div>

      <div class="colors__divider"></div>

      <div class="colors__text-colors">
        <div class="colors__color-heading">
          {{ $t("pages.brandmanager.colors.textColors") }}
        </div>
        <div class="colors__color-wrapper">
          <div class="colors__color-text-light">
            <label class="colors__color-label" for="colors__color-text-light">
              {{ $t("pages.brandmanager.colors.textLight") }}
            </label>
            <div
              class="colors__color"
              :style="{
                'background-color': textLight?.color
              }"
              id="colors__color-text-light"
              @click="openColorModal(textLight)"
            ></div>
          </div>

          <div class="colors__color-text-dark">
            <label class="colors__color-label" for="colors__color-text-dark">
              {{ $t("pages.brandmanager.colors.textDark") }}
            </label>
            <div
              class="colors__color"
              :style="{
                'background-color': textDark?.color
              }"
              id="colors__color-text-dark"
              @click="openColorModal(textDark)"
            ></div>
          </div>
        </div>
      </div>

      <div class="colors__divider"></div>

      <div
        class="colors__secondary-colors"
        v-if="brandColors"
        @mouseover="showColorDelete = true"
        @mouseleave="showColorDelete = false"
      >
        <div class="colors__color-heading">
          {{ $t("pages.brandmanager.colors.secondary") }}
        </div>

        <div class="colors__color-wrapper">
          <div
            class="colors__color colors__secondary-color"
            v-for="(color, index) in secondaryColors"
            :key="index"
            :style="{
              'background-color': color.color
            }"
            @mouseover="showColor(index)"
            @click="openColorModal(color)"
          >
            <div
              class="colors__delete"
              v-if="showColorIndex === index && showColorDelete"
            >
              <inline-svg
                class="colors__delete-icon"
                :src="require('../../assets/icons/X.svg')"
                aria-label="Close"
                @click="deleteColor(color.id)"
              ></inline-svg>
            </div>
          </div>
        </div>
      </div>
      <Button
        class="colors__add-color-btn"
        theme="secondary"
        @click="openColorModal()"
      >
        {{ $t("pages.brandmanager.colors.addButton") }}
      </Button>

      <ColorModal
        v-if="showColorModal"
        @close="closeColorModal"
        :color-type="colorType"
        :chosen-color="chosenColor"
      >
      </ColorModal>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Button from "@/components/Button";
import ColorModal from "@/components/ColorModal";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Card,
    Button,
    ColorModal
  },
  data: () => ({
    showColorModal: false,
    showColorDelete: false,
    showColorIndex: -1,
    colorType: "",
    chosenColor: "",
    excludedColors: ["primary", "textLight", "textDark"]
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam"
    }),
    brandColors() {
      if (this.team.brand && this.team.brand.colors) {
        let colors = Object.entries(this.team.brand.colors).map(
          ([id, color]) => {
            return { id, color: color.color };
          }
        );

        return colors;
      }
      return [];
    },
    primaryColor() {
      return this.brandColors.filter(color => color.id === "primary")[0];
    },
    textLight() {
      return this.brandColors.filter(color => color.id === "textLight")[0];
    },
    textDark() {
      return this.brandColors.filter(color => color.id === "textDark")[0];
    },
    secondaryColors() {
      return this.brandColors.filter(color => {
        return !this.excludedColors.includes(color.id);
      });
    }
  },
  methods: {
    ...mapActions("teams", ["updateTeam"]),
    ...mapActions("notifications", ["addNotification"]),
    showColor(index) {
      this.showColorIndex = index;
    },
    openColorModal(color) {
      this.showColorModal = true;
      this.colorType = color ? color.id : "secondary";
      this.chosenColor = color ? color.color : "";
    },
    closeColorModal() {
      this.showColorModal = false;
      this.colorType = "";
      this.chosenColor = "";
    },
    deleteColor(id) {
      delete this.team.brand.colors[id];

      this.updateTeam(this.team)
        .then(() => {
          this.addNotification({
            title: "colorDeleted",
            type: "success",
            autoRemove: true
          });
        })
        .catch(() => {
          this.addNotification({
            title: "error",
            type: "error",
            autoRemove: true
          });
        });
    }
  }
};
</script>

<style lang="scss">
.colors {
  &__color-wrapper {
    @apply mb-6 bg-cover flex;
    width: max-content;
  }

  &__color-heading {
    @apply font-semibold mb-6;
  }

  &__color-label {
    @apply text-xs font-semibold whitespace-nowrap mb-2;
  }

  &__color-text-light,
  &__color-text-dark {
    @apply flex flex-col items-center;
  }

  &__secondary-color,
  &__color-text-light {
    @apply mr-4;
  }

  &__color {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    @apply shadow relative cursor-pointer;
  }

  &__divider {
    height: 1px;
    @apply bg-ls-gray-200 w-full my-6;
  }

  &__add-color-btn {
    @apply mt-6;
  }

  &__delete {
    @apply absolute p-1 bg-ls-gray-600 cursor-pointer;
    top: -8px;
    right: -8px;
    border-radius: 50%;

    &-icon {
      width: 8px;
      height: 8px;

      path {
        @apply stroke-current text-white;
      }
    }
  }
}
</style>
