<template>
  <div class="font-upload">
    <Button
      class="font-upload__add-btn"
      theme="secondary"
      @click="showModal = true"
      icon="Plus"
    >
      {{ $t("components.fontUpload.addFontBtn") }}
    </Button>

    <Modal
      v-if="showModal"
      @close="modalClose"
      :heading="$t('components.fontUpload.heading')"
    >
      <template #modal-content>
        <div class="font-upload__preview">
          {{ chosenFont }}
        </div>
        <label for="font-upload-input" class="font-upload__upload-btn">
          {{ $t("components.fontUpload.chooseFontBtn") }}
        </label>
        <input
          class="font-upload__upload-btn-input"
          name="font-upload"
          type="file"
          id="font-upload-input"
          @change="onChange"
        />

        <Button
          v-if="hasChanged"
          class="font-upload__save-btn"
          @click="onUpload"
        >
          {{ $t("components.fontUpload.saveFontBtn") }}
        </Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Button from "@/components/Button";
import Modal from "@/components/Modal";

export default {
  components: {
    Button,
    Modal
  },
  data: () => ({
    showModal: false,
    chosenFont: "",
    fontFile: null
  }),
  computed: {
    hasChanged() {
      return !!this.fontFile;
    }
  },
  methods: {
    ...mapActions("teams", ["uploadFont"]),
    onChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      this.chosenFont = files[0].name;
      this.fontFile = files[0];

      this.setPreviewFont(this.fontFile);
    },
    setPreviewFont(font) {
      let reader = new FileReader();
      let newStyle = document.createElement("style");

      reader.onload = function(e) {
        newStyle.appendChild(
          document.createTextNode(
            "\
              @font-face {\
                  font-family: 'chosen_font';\
                  src: url('" +
              e.target.result +
              "');\
              }\
              "
          )
        );

        document.head.appendChild(newStyle);
      };

      reader.readAsDataURL(font);
    },
    onUpload() {
      let font = {
        file: this.fontFile,
        name: this.chosenFont,
        createdAt: new Date()
      };

      this.uploadFont(font);
      this.modalClose();
    },
    modalClose() {
      this.showModal = false;
      this.chosenFont = "";
      this.fontFile = null;
    }
  }
};
</script>

<style lang="scss">
.font-upload {
  &__title {
    @apply mb-2 font-semibold text-base text-ls-gray-800;
  }

  &__preview {
    @apply my-4 text-xl;
    font-family: "chosen_font";
  }

  &__upload-btn-input {
    display: none;
  }

  &__upload-btn {
    @apply border border-ls-gray-400 bg-white rounded-lg px-3 py-2 cursor-pointer text-xs font-semibold w-full text-center;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    display: inline-block;
  }

  &__save-btn {
    @apply mt-2 w-full;
  }
}
</style>
